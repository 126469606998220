import { ReactElement } from 'react';
import { To } from 'react-router';

export enum MenuType {
  DESKTOP,
  DESKTOP_LAB,
  TABLET,
  TABLET_LAB,
  MOBILE,
  MOBILE_LAB,
  PROFILE,
}

export type MenuItem = {
  menuId: number;
  featureId: number;
  enabledDevices: Array<MenuType>;
  name: string;
  to: To;
  isActive?: boolean;
  icon: ReactElement<any, any> | null;
  subMenus?: Array<MenuItems>;
  visibilityExpireSubscription: boolean;
  isSettings: boolean;
};

export type SubMenuItem = {
  name: string;
  to: To | undefined;
  icon: ReactElement<any, any> | null;
  isActive?: boolean;
  menuId: number;
  featureId: number;
  enabledDevices: Array<MenuType>;
  visibilityExpireSubscription: boolean;
};

export type MenuUiItem = {
  menuId: number;
  name: string;
  to: To;
  isActive?: boolean;
  icon: ReactElement<any, any> | null;
  subMenus?: Array<SubMenuUiItem>;
  visibilityExpireSubscription: boolean;
  isSettings: boolean;
};

export type SubMenuUiItem = {
  name: string;
  to: To | undefined;
  icon: ReactElement<any, any> | null;
  isActive?: boolean;
  menuId: number;
  visibilityExpireSubscription: boolean;
};

export enum MenuItems {
  DASHBOARD,
  CALLENDAR,
  CASES,
  INVOICE,
  FAQ,
  SETTINGS,
  WORKTYPES,
  PHASES,
  COLLABORATORS,
  COMAPANY_DATA,
  BILLING,
  MY_PROFILE,
  GENERAL,
  PROFILE,
  NOTIFICATIONS,
  PREFERENCES,
  MANAGE_ACOUNTS,
  LABS,
  LOGOUT,
  PLANS,
}
